const swap = {
	'swap.tips.no.wallet': '最初にウォレットを接続してください',
	'swap.tips.no.number': '数量を入力してください',
	'swap.tips.no.address': '受信アドレスを入力してください',
	'swap.tips.address.error': 'アドレスエラー',
	'swap.tips.error': '交換に失敗しました',
	'swap.tips.max': '最大金額: {{msg}}',
	'swap.tips.min': '最小金額: {{msg}}',

	'swap.card.send': '送信',
	'swap.card.receive': '受信',
	'swap.card.tips': '通貨またはチケットを入力',
	'swap.card.min': 'min:',
	'swap.card.max': 'max:',

	'swap.btn.title': '宛先',
	'swap.input.tips': '支払いアドレス',
	'swap.title.order': '注文タイプ',

	'swap.tab.1': '固定為替レート (1.0%)',
	'swap.tab.2': '変動為替レート (0.5%)',

	'swap.btn.tipss': '違いは何ですか? ',
	'swap.btn.swap': 'スワップの開始',

	'swap.tips.1': 'このサイトを使用して取引所を作成すると、Fast Coin Mixer に同意したことになります',
	'swap.tips.2': '利用規約',
	'swap.tips.3': 'そして',
	'swap.tips.4': 'プライバシー ポリシー',
	'swap.tips.5': '. ',

	'swap.diff.title': '固定為替レートと変動為替レートの違いは何ですか? ',

	'swap.paste.title': '貼り付け',
	'swap.help.title': 'よくある質問',
	'swap.help.1':
		'3 つの方法: 電子メール (電子メール アドレスを残す場合)、当社の Web サイト (ブラウザが Cookie を受け入れる場合)、または注文からのリンクによってブロックチェーン内のトランザクションを表示する。 ',
	'swap.help.2': `登録や個人情報の共有は必要ありません。当社はあなたの資金を保持しません。すべての交換は完全自動モードで即座に行われます。`,
	'swap.help.3': '正直であることが私たちの最優先事項であるため、私たちは完全な透明性を約束し、すべての手数料を明確にします:',
	'swap.help.4': '固定金利を選択した場合は 1%',
	'swap.help.5': '変動金利を選択した場合は 0.5%',
	'swap.help.6': 'FAQ ページに移動',
	'swap.help.title1': 'なぜあなたを信頼できるのですか?',
	'swap.help.title2': '隠れた料金はありますか?',

	'swap.tips.title': '2018 年以来信頼されています',
	'swap.tips.card.title1': '時間を節約',
	'swap.tips.card.title2': '一度交換',
	'swap.tips.card.title3': 'お金を節約',
	'swap.tips.card.content1': 'プロセス全体が自動化されているため、引き換え速度が最も速くなります',
	'swap.tips.card.content2': '適切な戦略を選択し、有益な交換を行ってください',
	'swap.tips.card.content3': '最高の為替レートと最低の手数料',

	'swap.trade.title': '最近の取引',
}

export default swap
