const swap = {
	'swap.tips.no.wallet': 'Please connect your wallet',
	'swap.tips.no.number': 'Please enter quantity',
	'swap.tips.no.address': 'Enter your Receive address',
	'swap.tips.address.error': 'Address Error',
	'swap.tips.error': 'Failed',
	'swap.tips.max': 'Max: {{msg}}',
	'swap.tips.min': 'Min: {{msg}}',

	'swap.card.send': 'Send',
	'swap.card.receive': 'Receive',
	'swap.card.tips': 'Type a currency or ticker',
	'swap.card.min': 'min:',
	'swap.card.max': 'max:',

	'swap.btn.title': 'Destination',
	'swap.input.tips': 'Payment address',
	'swap.title.order': 'Order type',

	'swap.tab.1': 'Fixed rate (1.0%)',
	'swap.tab.2': 'Float rate (0.5%)',

	'swap.btn.tipss': 'What is the difference?',
	'swap.btn.swap': 'Exchange now',

	'swap.tips.1': `By using the site and creating an exchange, you agree to the Fast Coin`,
	'swap.tips.2': 'Terms of Services',
	'swap.tips.3': ' and ',
	'swap.tips.4': 'Privacy Policy',
	'swap.tips.5': '.',

	'swap.diff.title': 'What is the difference between fixed and a float rates?',

	'swap.paste.title': 'Paste',
	'swap.help.title': 'FAQ',
	'swap.help.1':
		'In three ways: by email (if you leave your email address), on our website (if your browser accepts cookies) or by viewing the transactions in the blockchain by the links from your order.',
	'swap.help.2': `No registration and no need to share your personal details. We don't hold your funds, all exchanges take placeinstantly in fully automatic mode.`,
	'swap.help.3': 'Honesty is our main priority, so we commit to full transparency and make all the fees clear:',
	'swap.help.4': '•  1% if you opt for a fixed rate',
	'swap.help.5': '•  0.5% if you opt for a floating rate',
	'swap.help.6': 'Go to page FAQ',
	'swap.help.title1': 'Why can I trust you?',
	'swap.help.title2': 'Do you have hidden fees?',

	'swap.tips.title': 'Trusted since 2018',
	'swap.tips.card.title1': 'Save time',
	'swap.tips.card.title2': 'Make an exchange',
	'swap.tips.card.title3': 'Save money',
	'swap.tips.card.content1': 'Maximum exchange speed due to the full automation',
	'swap.tips.card.content2': 'Pick the right strategy and make favourable trades',
	'swap.tips.card.content3': 'Best exchange rates and minimum commissions',

	'swap.trade.title': 'Recent transactions',
}

export default swap
