const home = {
	'home.usdt.title': 'USDT 穩定幣',
	'home.usdt.max': '最大',
	'home.usdt.balance': '餘額：',
	'home.usdt.total': '總存入：',
	'home.total.balance': '流動池：',
	'home.btn.approve': '授權',
	'home.btn.stake': '存入',
	'home.withdraw.title': '可提現：',
	'home.withdraw.2': '贖回',
	'home.withdraw.earnings': '個人收益：',
	'home.withdraw.amount': '生態收益：',
	'home.withdraw.btn1': '領取',
	'home.withdraw.btn2': '再存入',
	'home.team.title': '生態總存入',
	'home.team.1': '地址：',
	'home.team.upper': '推薦人：',
	'home.team.address.placeholder': '請輸入使用者位址',
	'home.team.address.s': '已綁定',
	'home.team.leavel': '等級：',
	'home.team.bind': '綁定',
	'home.invite.title': '邀請連結',
	'home.invite.copy': '複製',
	'home.invite.tip': '請先啟動帳號',
	'home.message.tip0': '領取成功',
	'home.message.tip1': '授權成功',
	'home.message.tip2': '存入成功',
	'home.message.tip3': '最小質押金金額：{{msg}} USDT',
	'home.message.tip4': '目前帳戶餘額：{{msg}} USDT',
	'home.message.tip5': '複製成功',
	'home.message.tip6': '不能綁定自己的地址',
	'home.message.tip7': '綁定成功',
	'home.message.tip8': '贖回成功',
	'home.message.tip9': '目前帳戶最多可贖回數量：{{msg}} USDT',

	'home.check.order.title': '查看訂單',
	'home.2.stake.btn.1': '定期',
	'home.2.stake.btn.1.1': '(15天)',
	'home.2.stake.btn.2': '活期',
	'home.2.stake.btn.3': '費率：{{msg}}%',

	'home.orders.title': '我的訂單',
	'home.orders.tips1': '存入金額',
	'home.orders.tips2': '預計收益',
	'home.orders.tips3': '存入時間',
	'home.orders.tips4': '到期時間',
	'home.orders.tips5': '已領取',
	'home.orders.btn1': '領取',
	'home.orders.btn2': '已領取',
	'home.orders.no.more': '沒有更多了',
	'home.orders.claim.success': '領取成功',

	'home.2.withdraw.title': '距離下次領取的時間：',

	'home.regular.title': '定期收益',
	'home.regular.reward.success': '領取成功',
	'home.regular.withdraw.success': '贖回成功',
	'home.regular.tips1': '可贖回金額:',
	'home.regular.tips2': '可領取收益:',
	'home.regular.btn1': '贖回',
	'home.regular.btn2': '領取收益',

	'home.team.list.title1': '個人投資',
	'home.team.list.title2': '團隊總績效',
	'home.team.list.title3': '有效邀請',

	'home.state.order.tips.max': '進行中的訂單不可超過10筆',
}

export default home
