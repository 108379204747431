import axios from 'axios'
import { baseURL } from '@/utils'
import toast from 'react-hot-toast'
import { getToken } from './local'

/** Create Axios instance */
const service = axios.create({
	timeout: 180000,
	baseURL,
	headers: {
		'Content-Type': 'application/json;charset=utf-8',
	},
})

/** request Interceptor */
service.interceptors.request.use(
	config => {
		if (config.headers) {
			config.headers['token'] = `${getToken()}`
		}
		return config
	},
	(error: any) => {
		Promise.reject(error)
	},
)

enum MsgError {
	'params.error' = 'Invalid parameters',
	'login.error' = 'Login failed, please try again',
	'api.request.error' = 'Request failed, please try again',
	'create.address.error' = 'Receiving address error',
	'order.error' = 'Invalid order',
}

/** Response interceptor */
service.interceptors.response.use(
	res => {
		if (res.data.code === 1) {
			toast.error((MsgError as any)[res.data.msg as any] || res.data.msg, { id: 'axios-error', duration: 2000 })
		}
		return res.data
	},
	error => {
		toast.error(`${error.message}.Please refresh the page and try again`, { id: 'axios-error', duration: 2000 })
		return Promise.reject(error)
	},
)

export default service
