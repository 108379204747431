const about = {
	'about.title': 'о нас',
	'about.content':
		'Криптовалюты открывают новые возможности для достижения финансовой свободы. Чем больше пользовательская база, тем больше конкурентное преимущество предлагают новые технологии децентрализованного реестра. Fast Coin предоставляет вам инструменты, позволяющие максимально эффективно использовать ваши цифровые активы с помощью простой в использовании платформы обмена.',
	'about.title2': 'Преимущества',
	'about.title2.tips1': 'Максимальное удобство обмена и возможность выбора стратегии позволят вам совершать выгодные обмены',
	'about.title2.tips2': 'Мы обеспечиваем безопасный обмен. Вы ничем не рискуете и сразу видите свою комиссию.',
	'about.title2.tips3': 'Вы можете автоматически погасить с максимальной скоростью на любом устройстве',
	'about.title3': 'Миссия',
	'about.title3.tips1': `Мы — дальновидный долгосрочный игрок, который надеется стать вашим надежным и проверенным партнером в области цифровых активов. Наша миссия — заставить криптоэкономику работать на вас, упростив процесс обмена с помощью практичных и масштабируемых решений. `,
	'about.title4': 'Поддерживаемые валюты',
}

export default about
