import menu from './en/menu'
import home from './en/home'
import chat from './en/chat'
import other from './en/other'
import about from './en/about'
import swap from './en/swap'

const lang = {
	'app.switch.language.tips': 'Switch {{msg}} Success',
	'app.language': 'Language',
	'app.loading': 'loading...',
	'app.address.error.tips': 'Address Error',

	'connect.sign': 'Sign...',
	'connect.btn': 'Connect',
	'connect.logout': 'logout',
	'connect.sign.error': 'Signature verification failed',

	'layout.menu.stake': 'Stake',
	'layout.menu.withdraw': 'Withdraw',
	'layout.menu.team': 'Community',
	'layout.menu.share': 'Share',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
