const swap = {
	'swap.tips.no.wallet': 'Vui lòng kết nối ví của bạn',
	'swap.tips.no.number': 'Vui lòng nhập số lượng',
	'swap.tips.no.address': 'Nhập địa chỉ nhận của bạn',
	'swap.tips.address.error': 'Lỗi địa chỉ',
	'swap.tips.error': 'Không thành công',
	'swap.tips.max': 'Tối đa: {{msg}}',
	'swap.tips.min': 'Tối thiểu: {{msg}}',

	'swap.card.send': 'Gửi',
	'swap.card.receive': 'Nhận',
	'swap.card.tips': 'Nhập loại tiền tệ hoặc mã chứng khoán',
	'swap.card.min': 'tối thiểu:',
	'swap.card.max': 'tối đa:',

	'swap.btn.title': 'Điểm đến',
	'swap.input.tips': 'Địa chỉ thanh toán',
	'swap.title.order': 'Loại lệnh',

	'swap.tab.1': 'Tỷ giá cố định (1,0%)',
	'swap.tab.2': 'Tỷ giá thả nổi (0,5%)',

	'swap.btn.tipss': 'Sự khác biệt là gì?',
	'swap.btn.swap': 'Đổi ngay',

	'swap.tips.1': `Bằng cách sử dụng trang web và tạo giao dịch, bạn đồng ý với Fast Coin`,
	'swap.tips.2': 'Điều khoản dịch vụ',
	'swap.tips.3': 'và',
	'swap.tips.4': 'Chính sách bảo mật',
	'swap.tips.5': '.',

	'swap.diff.title': 'Sự khác biệt giữa cố định và thả nổi là gì rates?',

	'swap.paste.title': 'Dán',
	'swap.help.title': 'Câu hỏi thường gặp',
	'swap.help.1':
		'Theo ba cách: qua email (nếu bạn để lại địa chỉ email), trên trang web của chúng tôi (nếu trình duyệt của bạn chấp nhận cookie) hoặc bằng cách xem các giao dịch trong chuỗi khối bằng các liên kết từ đơn đặt hàng của bạn.',
	'swap.help.2': `Không cần đăng ký và không cần chia sẻ thông tin cá nhân của bạn. Chúng tôi không giữ tiền của bạn, tất cả các giao dịch đều diễn ra ngay lập tức ở chế độ hoàn toàn tự động. `,
	'swap.help.3':
		'Sự trung thực là ưu tiên hàng đầu của chúng tôi, vì vậy chúng tôi cam kết minh bạch hoàn toàn và làm rõ mọi khoản phí:',
	'swap.help.4': '•1% nếu bạn chọn tỷ giá cố định',
	'swap.help.5': '•0,5% nếu bạn chọn tỷ giá thả nổi',
	'swap.help.6': 'Đi đến trang Câu hỏi thường gặp',
	'swap.help.title1': 'Tại sao tôi có thể tin tưởng bạn?',
	'swap.help.title2': 'Bạn có phí ẩn không?',

	'swap.tips.title': 'Được tin cậy từ năm 2018',
	'swap.tips.card.title1': 'Tiết kiệm thời gian',
	'swap.tips.card.title2': 'Thực hiện giao dịch',
	'swap.tips.card.title3': 'Tiết kiệm tiền',
	'swap.tips.card.content1': 'Tốc độ trao đổi tối đa do tự động hóa hoàn toàn',
	'swap.tips.card.content2': 'Chọn chiến lược phù hợp và thực hiện các giao dịch có lợi',
	'swap.tips.card.content3': 'Tỷ giá hối đoái tốt nhất và hoa hồng tối thiểu',

	'swap.trade.title': 'Các giao dịch gần đây',
}

export default swap
