import useSWR from 'swr'
import { DEFAULT_CHAINID } from '~/config/*'
import { multicall } from '@/lib/web3/multicall'
import getChainConfig from '@/lib/web3/getChainConfig'
import { VAULTPROXY } from '@/contract/abi'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

export default function useShareHooks() {
	const currentChainId = DEFAULT_CHAINID
	const { isConnected, address } = useWeb3ModalAccount()

	// 合约信息
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']

	const fetcher = async () => {
		return await multicall(currentChainId, {
			contracts: [
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'users',
					args: [address],
				},
			],
		}).then(res => {
			const users: any = (res[0].result || []) as number[]
			console.log('users', users)
			return {
				isUserStaked: res[0].status === 'success' ? users[10] : false,
			}
		})
	}

	const { data, ...args } = useSWR(isConnected ? `useShareHooks/${address}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		data: {
			isUserStaked: data?.isUserStaked || false,
		},
		...args,
	}
}
