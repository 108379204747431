const home = {
	'home.usdt.title': 'USDT (стейблкоин)',
	'home.usdt.max': 'Максимум',
	'home.usdt.balance': 'Баланс:',
	'home.usdt.total': 'Общая сумма:',
	'home.total.balance': 'Общий баланс:',
	'home.btn.approve': 'Подтвердить',
	'home.btn.stake': 'Вложить',
	'home.withdraw.title': 'Доступно к выводу:',
	'home.withdraw.2': 'Вывести',
	'home.withdraw.earnings': 'Личный доход:',
	'home.withdraw.amount': 'Доход сети:',
	'home.withdraw.btn1': 'Получить',
	'home.withdraw.btn2': 'Реинвестировать',
	'home.team.title': 'Общий вклад сети',
	'home.team.1': 'Адрес:',
	'home.team.upper': 'Реферальный адрес:',
	'home.team.address.placeholder': 'Введите адрес пользователя',
	'home.team.address.s': 'Адрес привязан',
	'home.team.leavel': 'Уровень:',
	'home.team.bind': 'Привязать',
	'home.invite.title': 'Пригласительная ссылка',
	'home.invite.copy': 'Копировать',
	'home.invite.tip': 'Сначала активируйте аккаунт',
	'home.message.tip0': 'Успешно получено!',
	'home.message.tip1': 'Авторизация выполнена!',
	'home.message.tip2': 'Стейкинг выполнен!',
	'home.message.tip3': 'Минимальная сумма для стейкинга: {{msg}} USDT',
	'home.message.tip4': 'Текущий баланс: {{msg}} USDT',
	'home.message.tip5': 'Скопировано!',
	'home.message.tip6': 'Нельзя привязать собственный адрес',
	'home.message.tip7': 'Адрес успешно привязан!',
	'home.message.tip8': 'Успешно выведено!',
	'home.message.tip9': 'Максимальная сумма для вывода: {{msg}} USDT',

	'home.check.order.title': 'Проверить заказ',
	'home.2.stake.btn.1': 'Обычный',
	'home.2.stake.btn.1.1': '(15 дней)',
	'home.2.stake.btn.2': 'текущий',
	'home.2.stake.btn.3': 'Ставка: {{msg}}%',

	'home.orders.title': 'Мои заказы',
	'home.orders.tips1': 'Сумма депозита',
	'home.orders.tips2': 'Ожидаемый доход',
	'home.orders.tips3': 'Время депозита',
	'home.orders.tips4': 'Срок действия',
	'home.orders.tips5': 'Получено',
	'home.orders.btn1': 'Получить',
	'home.orders.btn2': 'Получено',
	'home.orders.no.more': 'Больше нет',
	'home.orders.claim.success': 'Заявка выполнена успешно',

	'home.2.withdraw.title': 'Время до следующего вывода:',

	'home.regular.title': 'Регулярный доход',
	'home.regular.reward.success': 'Получено успешно',
	'home.regular.withdraw.success': 'Выкуп успешен',
	'home.regular.tips1': 'Сумма к выкупу:',
	'home.regular.tips2': 'Вы можете получать доход:',
	'home.regular.btn1': 'Погасить',
	'home.regular.btn2': 'Получать доход',

	'home.team.list.title1': 'Личные инвестиции',
	'home.team.list.title2': 'Общая производительность команды',
	'home.team.list.title3': 'Действующее приглашение',

	'home.state.order.tips.max': 'Количество заказов в обработке не может превышать 10',
}

export default home
