import Footer from '@/components/Footer'
import HomeSwap from './components/Swap'
import HomeTips from './components/Tips'
import HomeTrade from './components/Trade'
import HomeHelp from './components/Help'

export default function HomeTwoPage() {
	return (
		<div className="pt-[1.625rem] sm:h-full sm:overflow-hidden sm:overflow-y-scroll sm:pt-[5.125rem]">
			<HomeSwap />
			<HomeTips />
			<HomeTrade />
			<HomeHelp />
			<Footer />
		</div>
	)
}
