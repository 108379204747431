const home = {
	'home.usdt.title': 'Tether USD',
	'home.usdt.max': 'MAX',
	'home.usdt.balance': 'ยอดคงเหลือของคุณ:',
	'home.usdt.total': 'ยอดเดิมพันรวม:',
	'home.total.balance': 'Flow Cell:',
	'home.btn.approve': 'อนุมัติ',
	'home.btn.stake': 'ยอดเดิมพัน',

	'home.withdraw.title': 'ถอนได้:',
	'home.withdraw.2': 'ถอนได้',
	'home.withdraw.earnings': 'รายได้ที่ไม่ต้องลงแรง:',
	'home.withdraw.amount': 'ผลประโยชน์ของชุมชน:',
	'home.withdraw.btn1': 'รับรางวัล',
	'home.withdraw.btn2': 'Restake',

	'home.team.title': 'Total Community Staking',
	'home.team.1': 'Address:',
	'home.team.upper': 'Referrer:',
	'home.team.address.placeholder': 'กรุณากรอกที่อยู่ผู้ใช้',
	'home.team.address.s': 'Bound',
	'home.team.leavel': 'Level:',
	'home.team.bind': 'Bind Relation',

	'home.invite.title': 'ลิงก์คำเชิญ',
	'home.invite.copy': 'คัดลอกลิงก์',
	'home.invite.tip': 'กรุณาเปิดใช้งานบัญชีของคุณก่อน',

	'home.message.tip0': 'ได้รับเรียบร้อยแล้ว',
	'home.message.tip1': 'อนุมัติสำเร็จ',
	'home.message.tip2': 'เดิมพันสำเร็จ',
	'home.message.tip3': 'จำนวนเงินขั้นต่ำที่จำนำ: {{msg}} USDT',
	'home.message.tip4': 'ยอดเงินคงเหลือในบัญชีปัจจุบัน: {{msg}} USDT',
	'home.message.tip5': 'คัดลอกสำเร็จ',
	'home.message.tip6': 'ผูกที่อยู่ของคุณเองไม่ได้',
	'home.message.tip7': 'ผูกสำเร็จ',
	'home.message.tip8': 'แลกสำเร็จ',
	'home.message.tip9': 'จำนวนเงินสูงสุดที่แลกได้ในบัญชีปัจจุบัน: {{msg}} USDT',

	// 2.0
	'home.check.order.title': 'ตรวจสอบคำสั่งซื้อ',
	'home.2.stake.btn.1': 'ปกติ',
	'home.2.stake.btn.1.1': '(15 วัน)',
	'home.2.stake.btn.2': 'ปัจจุบัน',
	'home.2.stake.btn.3': 'อัตรา: {{msg}}%',

	'home.orders.title': 'คำสั่งซื้อของฉัน',
	'home.orders.tips1': 'จำนวนเงินฝาก',
	'home.orders.tips2': 'คาดว่า',
	'home.orders.tips3': 'เวลาฝากเงิน',
	'home.orders.tips4': 'เวลาหมดอายุ',
	'home.orders.tips5': 'ได้รับแล้ว',
	'home.orders.btn1': 'รับเงิน',
	'home.orders.btn2': 'ได้รับแล้ว',
	'home.orders.no.more': 'ไม่มีข้อมูลเพิ่มเติม',
	'home.orders.claim.success': 'ได้รับเงินเรียบร้อยแล้ว',

	'home.2.withdraw.title': 'เวลาจนกว่าจะรับเงินครั้งต่อไป:',

	'home.regular.title': 'รายได้ประจำ',
	'home.regular.reward.success': 'รับเงินเรียบร้อยแล้ว',
	'home.regular.withdraw.success': 'แลกเงินเรียบร้อยแล้ว',
	'home.regular.tips1': 'จำนวนเงินที่แลกได้:',
	'home.regular.tips2': 'รายได้ที่มีอยู่:',
	'home.regular.btn1': 'ถอนเงิน',
	'home.regular.btn2': 'รับรางวัล',

	'home.team.list.title1': 'การลงทุนส่วนตัว',
	'home.team.list.title2': 'ประสิทธิภาพโดยรวมของทีม',
	'home.team.list.title3': 'คำเชิญที่ถูกต้อง',

	'home.state.order.tips.max': 'คำสั่งซื้อที่กำลังดำเนินการไม่สามารถเกิน 10 คำสั่งซื้อ',
}

export default home
