import { createPublicClient, http, PublicClient } from 'viem'
import { polygonAmoy as polygonViem } from 'viem/chains'

const polygon = {
	...polygonViem,
	rpcUrls: {
		default: {
			http: ['https://rpc-amoy.polygon.technology'],
		},
	},
}

export const SUPPER_CHAINS = [polygon]

export const SUPPER_NEXTWORK_NAME = {
	[polygon.id]: 'Polygon',
}

// Current Supper Chainds
export const CURRENT_SUPPER_CHAINDS = [polygon.id]

// 当前默认CHAINID
export const DEFAULT_CHAINID = CURRENT_SUPPER_CHAINDS[0]

export type ISwitchNetWrok = {
	chainId: `0x${string}`
	chainName: string
	rpcUrls: string[]
	nativeCurrency: any
	blockExplorerUrls: string[]
}

// 当前切换网络需要的参数
export const switchNetwork: {
	[x: number]: ISwitchNetWrok
} = {
	80002: {
		chainId: `0x${polygon.id.toString(16)}`,
		chainName: polygon.name,
		rpcUrls: [polygon.rpcUrls.default.http[0]],
		nativeCurrency: polygon.nativeCurrency,
		blockExplorerUrls: [polygon.blockExplorers.default.url],
	},
}

export enum ContractName {
	VaultProxy_ADDRESS = 'VaultProxy_ADDRESS',
	USDT_ADDRESS = 'USDT_ADDRESS',
	AUSDT_ADDRESS = 'AUSDT_ADDRESS',
}

type IContractsConfig = {
	[x in ContractName]: `0x${string}`
}
type IOtherConfig = {
	symbol: string
	rpcUrl: string
	explorerUrl: string
}

export type Contracts = IContractsConfig & IOtherConfig

// 合约配置 & 其他信息配置
export const contracts: {
	[x: number]: Contracts
} = {
	80002: {
		VaultProxy_ADDRESS: '0x7455c89ADa0d1D58932d2C0C34bCfa796EbC1B85',
		USDT_ADDRESS: '0xA1960B66eA82367f3848C378d5f89888f5bA44d0',
		AUSDT_ADDRESS: '0xA1960B66eA82367f3848C378d5f89888f5bA44d0',
		symbol: polygon.nativeCurrency.symbol,
		explorerUrl: polygon.blockExplorers.default.url,
		rpcUrl: polygon.rpcUrls.default.http[0],
	},
}

export const multicall3: {
	[x: number]: {
		publicClient: PublicClient
		multicallAddress: `0x${string}`
	}
} = {
	80002: {
		publicClient: createPublicClient({
			chain: polygon,
			transport: http(),
		}),
		multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
	},
}
