import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import enUsTrans from './en'
import zhCnTrans from './zh'
import ko from './ko'
import ja from './ja'
import ru from './ru'
import tai from './tai'
import vi from './vi'
import { initReactI18next } from 'react-i18next'

const resources = {
	en: {
		translation: enUsTrans,
	},
	zh: {
		translation: zhCnTrans,
	},
	ko: {
		translation: ko,
	},
	ja: {
		translation: ja,
	},
	ru: {
		translation: ru,
	},
	tai: {
		translation: tai,
	},
	vi: {
		translation: vi,
	},
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		// lng: localStorage.getItem('i18nextLng') || (navigator.language === 'zh-CN' ? 'zh' : navigator.language === 'zh' ? 'zh' : 'en'),
		lng: localStorage.getItem('i18nextLng') || 'en',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
