const menu = {
	'menu.home': 'Главная',
	'menu.contract': 'Контракты',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'Опционный контракт',
	'menu.option.ai': 'AI для арбитража',
	'menu.option.qr': 'Квантовый бот',
	'menu.option.ai.mall': 'Умный AI-магазин',

	'menu.my.orders': 'Мои заказы',
	'menu.stake': 'Ставка',
	'menu.about': 'О нас',
	'menu.about.faq': 'Часто задаваемые вопросы',
	'menu.serve': 'Условия обслуживания',
	'menu.privacy': 'Политика конфиденциальности',
}

export default menu
