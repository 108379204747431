import menu from './tai/menu'
import home from './tai/home'
import chat from './tai/chat'
import other from './tai/other'
import about from './tai/about'
import swap from './tai/swap'

const lang = {
	'app.switch.language.tips': 'เปลี่ยน {{msg}} สำเร็จ',
	'app.language': 'ภาษา',
	'app.loading': 'กำลังโหลด...',
	'app.address.error.tips': 'ข้อผิดพลาดที่อยู่',

	'connect.sign': 'ลงชื่อ...',
	'connect.btn': 'เชื่อมต่อ',
	'connect.logout': 'ออกจากระบบ',
	'connect.sign.error': 'การตรวจสอบลายเซ็นล้มเหลว',

	'layout.menu.stake': 'Stake',
	'layout.menu.withdraw': 'ถอนตัว',
	'layout.menu.team': 'ชุมชน',
	'layout.menu.share': 'แชร์',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
