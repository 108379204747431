const home = {
	'home.usdt.title': 'USDT ステーブルコイン',
	'home.usdt.max': '最大',
	'home.usdt.balance': '残高:',
	'home.usdt.total': '総入金額:',
	'home.total.balance': 'フローセル:',
	'home.btn.approve': '承認',
	'home.btn.stake': '保存',

	'home.withdraw.title': '出金可能:',
	'home.withdraw.2': '償還',
	'home.withdraw.earnings': '個人の収入:',
	'home.withdraw.amount': '生態学的利点:',
	'home.withdraw.btn1': '受信',
	'home.withdraw.btn2': '再入金',

	'home.team.title': '生態学的総堆積物',
	'home.team.1': 'アドレス:',
	'home.team.upper': '推奨者:',
	'home.team.address.placeholder': 'ユーザーアドレスを入力してください',
	'home.team.address.s': 'バインド',
	'home.team.leavel': 'レベル:',
	'home.team.bind': 'バインド',

	'home.invite.title': '招待リンク',
	'home.invite.copy': 'コピー',
	'home.invite.tip': '最初にアカウントをアクティブにしてください',

	'home.message.tip0': '正常に受信しました',
	'home.message.tip1': '認証成功',
	'home.message.tip2': '入金成功',
	'home.message.tip3': '最低入金額: {{msg}} USDT',
	'home.message.tip4': '現在のアカウント残高: {{msg}} USDT',
	'home.message.tip5': 'コピーに成功しました',
	'home.message.tip6': '自分のアドレスをバインドできません',
	'home.message.tip7': 'バインド成功',
	'home.message.tip8': '引き換えに成功しました',
	'home.message.tip9': 'アカウントの現在の最大引き換え額: {{msg}} USDT',

	'home.check.order.title': '注文を表示',
	'home.2.stake.btn.1': 'レギュラー',
	'home.2.stake.btn.1.1': '(15 日)',
	'home.2.stake.btn.2': '現在',
	'home.2.stake.btn.3': 'レート: {{msg}}%',

	'home.orders.title': '私の注文',
	'home.orders.tips1': '入金額',
	'home.orders.tips2': '推定収益',
	'home.orders.tips3': '入金時間',
	'home.orders.tips4': '有効期限',
	'home.orders.tips5': '受け取った',
	'home.orders.btn1': '受け取る',
	'home.orders.btn2': '受け取りました',
	'home.orders.no.more': 'これ以上はありません',
	'home.orders.claim.success': '請求が成功しました',

	'home.2.withdraw.title': '次の出金までの時間:',

	'home.regular.title': '定期収入',
	'home.regular.reward.success': '受信成功',
	'home.regular.withdraw.success': '償還が成功しました',
	'home.regular.tips1': '引き換え可能な金額:',
	'home.regular.tips2': '利用可能な収入:',
	'home.regular.btn1': '償還',
	'home.regular.btn2': '収入を受け取る',

	'home.team.list.title1': '個人投資',
	'home.team.list.title2': 'チーム全体のパフォーマンス',
	'home.team.list.title3': '有効な招待',

	'home.state.order.tips.max': '進行中の注文の数は 10 を超えることはできません',
}

export default home
