const home = {
	'home.usdt.title': 'USDT 스테이블코인',
	'home.usdt.max': '최대',
	'home.usdt.balance': '잔액:',
	'home.usdt.total': '총 입금액:',
	'home.total.balance': '플로우 셀:',
	'home.btn.approve': '승인',
	'home.btn.stake': '저장',

	'home.withdraw.title': '출금 가능 여부:',
	'home.withdraw.2': '구원',
	'home.withdraw.earnings': '개인 수입:',
	'home.withdraw.amount': '생태학적 이점:',
	'home.withdraw.btn1': '수신',
	'home.withdraw.btn2': '재입금',

	'home.team.title': '생태학적 총 예금',
	'home.team.1': '주소:',
	'home.team.upper': '추천자:',
	'home.team.address.placeholder': '사용자 주소를 입력하세요',
	'home.team.address.s': '바운드',
	'home.team.leavel': '레벨:',
	'home.team.bind': '바인딩',

	'home.invite.title': '초대 링크',
	'home.invite.copy': '복사',
	'home.invite.tip': '먼저 계정을 활성화하세요',

	'home.message.tip0': '성공적으로 수신되었습니다',
	'home.message.tip1': '인증 성공',
	'home.message.tip2': '입금 성공',
	'home.message.tip3': '최소 입금액: {{msg}} USDT',
	'home.message.tip4': '현재 계정 잔액: {{msg}} USDT',
	'home.message.tip5': '복사 성공',
	'home.message.tip6': '자신의 주소를 바인딩할 수 없습니다',
	'home.message.tip7': '바인딩 성공',
	'home.message.tip8': '사용 성공',
	'home.message.tip9': '현재 계정의 최대 상환 금액: {{msg}} USDT',

	'home.check.order.title': '주문 보기',
	'home.2.stake.btn.1': '일반',
	'home.2.stake.btn.1.1': '(15일)',
	'home.2.stake.btn.2': '현재',
	'home.2.stake.btn.3': '비율: {{msg}}%',

	'home.orders.title': '내 주문',
	'home.orders.tips1': '입금액',
	'home.orders.tips2': '예상 수입',
	'home.orders.tips3': '입금 시간',
	'home.orders.tips4': '만료 시간',
	'home.orders.tips5': '받았다',
	'home.orders.btn1': '수신',
	'home.orders.btn2': '접수됨',
	'home.orders.no.more': '더 이상',
	'home.orders.claim.success': '성공적으로 소유권 주장',

	'home.2.withdraw.title': '다음 출금까지 남은 시간:',

	'home.regular.title': '정기 수입',
	'home.regular.reward.success': '수신 성공',
	'home.regular.withdraw.success': '상환 성공',
	'home.regular.tips1': '상환 가능한 금액:',
	'home.regular.tips2': '가용 소득:',
	'home.regular.btn1': '구원',
	'home.regular.btn2': '수입 받기',

	'home.team.list.title1': '개인 투자',
	'home.team.list.title2': '팀 전체 성과',
	'home.team.list.title3': '유효한 초대',

	'home.state.order.tips.max': '진행 중인 주문 수는 10을 초과할 수 없습니다',
}

export default home
