const home = {
	'home.usdt.title': 'Tether USD',
	'home.usdt.max': 'MAX',
	'home.usdt.balance': 'Số dư của bạn:',
	'home.usdt.total': 'Tổng số tiền đặt cược:',
	'home.total.balance': 'Flow Cell:',
	'home.btn.approve': 'Phê duyệt',
	'home.btn.stake': 'Đặt cược',

	'home.withdraw.title': 'Có thể rút:',
	'home.withdraw.2': 'Rút',
	'home.withdraw.earnings': 'Thu nhập thụ động:',
	'home.withdraw.amount': 'Lợi ích cộng đồng:',
	'home.withdraw.btn1': 'Nhận phần thưởng',
	'home.withdraw.btn2': 'Đặt cược lại',

	'home.team.title': 'Tổng số tiền đặt cược của cộng đồng Staking',
	'home.team.1': 'Địa chỉ:',
	'home.team.upper': 'Người giới thiệu:',
	'home.team.address.placeholder': 'Vui lòng nhập địa chỉ người dùng',
	'home.team.address.s': 'Bound',
	'home.team.leavel': 'Level:',
	'home.team.bind': 'Mối quan hệ ràng buộc',

	'home.invite.title': 'Liên kết mời',
	'home.invite.copy': 'Sao chép liên kết',
	'home.invite.tip': 'Vui lòng kích hoạt tài khoản của bạn trước',

	'home.message.tip0': 'Đã nhận thành công',
	'home.message.tip1': 'Ủy quyền thành công',
	'home.message.tip2': 'Stake thành công',
	'home.message.tip3': 'Số tiền cam kết tối thiểu: {{msg}} USDT',
	'home.message.tip4': 'Số dư tài khoản hiện tại: {{msg}} USDT',
	'home.message.tip5': 'Sao chép thành công',
	'home.message.tip6': 'Không thể liên kết địa chỉ của riêng bạn',
	'home.message.tip7': 'Liên kết thành công',
	'home.message.tip8': 'Đổi thành công',
	'home.message.tip9': 'Số tiền tối đa có thể đổi trong tài khoản hiện tại: {{msg}} USDT',

	// 2.0
	'home.check.order.title': 'Kiểm tra lệnh',
	'home.2.stake.btn.1': 'Thông thường',
	'home.2.stake.btn.1.1': '(15 ngày)',
	'home.2.stake.btn.2': 'Hiện tại',
	'home.2.stake.btn.3': 'Tỷ lệ: {{msg}}%',

	'home.orders.title': 'Đơn hàng của tôi',
	'home.orders.tips1': 'Số tiền gửi',
	'home.orders.tips2': 'Dự kiến',
	'home.orders.tips3': 'Thời gian gửi',
	'home.orders.tips4': 'Thời gian hết hạn',
	'home.orders.tips5': 'Đã nhận',
	'home.orders.btn1': 'Thu thập',
	'home.orders.btn2': 'Đã nhận',
	'home.orders.no.more': 'Không còn dữ liệu nào nữa',
	'home.orders.claim.success': 'Đã nhận thành công',

	'home.2.withdraw.title': 'Thời gian cho đến lần thu thập tiếp theo:',

	'home.regular.title': 'Thu nhập thường xuyên',
	'home.regular.reward.success': 'Biên lai thành công',
	'home.regular.withdraw.success': 'Đổi thưởng thành công',
	'home.regular.tips1': 'Số tiền có thể đổi thưởng:',
	'home.regular.tips2': 'Thu nhập khả dụng:',
	'home.regular.btn1': 'Rút thưởng',
	'home.regular.btn2': 'Nhận thưởng',

	'home.team.list.title1': 'Đầu tư cá nhân',
	'home.team.list.title2': 'Tổng hiệu suất của nhóm',
	'home.team.list.title3': 'Lời mời hợp lệ',

	'home.state.order.tips.max': 'Số lượng đơn hàng đang xử lý không được vượt quá 10',
}

export default home
