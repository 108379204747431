const other = {
	'other.help.title': 'FAQ',
	'other.serve.title': 'Условия обслуживания',
	'other.serve.tips': 'Последнее обновление 30 июля 2024 г.',
	'other.privacy.title': 'Политика конфиденциальности',
	'other.privacy.tips': 'Последнее обновление 14 января 2023 г.',

	'orders.no.data': 'Идентификатор заказа не найден',
	'orders.details.send': 'Отправить',
	'orders.details.receive': 'Получить',
	'orders.details.orderid': 'Идентификатор заказа',
	'orders.details.difftime': 'Оставшееся время',
	'orders.details.type': 'Тип заказа',
	'orders.details.status1': 'Фиксированный обменный курс',
	'orders.details.status2': 'Плавающий обменный курс',
	'orders.details.createTime': 'Время создания',
	'orders.details.address': 'Адрес',
	'orders.details.with.amount': 'Включая сумму',
	'orders.details.b.send': 'Отправить',
	'orders.details.b.send1': 'Адрес получателя:',
	'orders.details.b.s': 'Курс обмена будет определен при получении',
	'orders.details.b.s1': 'Подтверждение сети. ',
	'orders.details.b.s2': 'Адрес доставки',
	'orders.details.progress.1': 'Ожидание депозита',
	'orders.details.progress.2': 'Ожидание подтверждения',
	'orders.details.progress.3': 'Выполнить обмен',
	'orders.details.progress.4': 'Завершено',

	'orders.list.1': 'Дата/ID OROER',
	'orders.list.2': 'Отправить',
	'orders.list.3': 'Получить',
	'orders.list.4': 'Адрес доставки',
	'orders.title': 'Выполненные заказы',
}

export default other
