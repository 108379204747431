import { useCallback, useEffect, useState } from 'react'

import { SubTitle } from './components'
import { Button, Input, Pagination, Spinner } from '@nextui-org/react'
import { Tree, Select, Input as InputAntd } from 'antd'
import { numFormat } from '@/utils'
import { serverQlUserInfo } from './graphql'
import { nanoid } from 'nanoid'
import BigNumber from 'bignumber.js'
import { LeavelValueInit } from '@/utils/leavel'
import useChatHooks from '@/hooks/useChatHooks'
import { encodeFunctionData, formatEther, isAddress, parseEther } from 'viem'
import toast from 'react-hot-toast'
import getChainConfig from '@/lib/web3/getChainConfig'
import { multicall } from '@/lib/web3/multicall'
import { DEFAULT_CHAINID } from '~/config/*'
import { VAULTPROXY } from '@/contract/abi'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'

// 用户信息
export type IUserInfo = {
	stakeAmount: string
	withdrawnAmount: string
	rewardPaidAmount: string
	teamAddressTotal: string
	teamAddressList: string[]
	teamStakeAmount: string
	teamWithdrawnAmount: string
	teamRewardPaidAmount: string
	referrer: string
	stakeAmountTotal: string
	leavel: number
}

export const UserInfoInit: IUserInfo = {
	stakeAmount: '0',
	withdrawnAmount: '0',
	rewardPaidAmount: '0',
	teamAddressTotal: '0',
	teamAddressList: [],
	teamStakeAmount: '0',
	teamWithdrawnAmount: '0',
	teamRewardPaidAmount: '0',
	referrer: '',
	stakeAmountTotal: '0',
	leavel: 0,
}

const SelectOptions = [
	{ value: 0, label: '0級' },
	{ value: 10, label: '1級' },
	{ value: 20, label: '2級' },
	{ value: 30, label: '3級' },
	{ value: 40, label: '4級' },
	{ value: 50, label: '5級' },
	{ value: 60, label: '6級' },
	{ value: 70, label: '7級' },
	{ value: 80, label: '8級' },
	{ value: 90, label: '9級' },
	{ value: 100, label: '10級' },
]

interface DataNode {
	title: string
	key: string
	isLeaf?: boolean
	children?: DataNode[]
}

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
	list.map(node => {
		if (node.key === key) {
			return {
				...node,
				children,
			}
		}
		if (node.children) {
			return {
				...node,
				children: updateTreeData(node.children, key, children),
			}
		}
		return node
	})

// 当前最多可以提取的比例
const WITH_AMOUNT_NUM = 0.05

const CountPage = () => {
	const currentChainId = DEFAULT_CHAINID
	const { isConnected } = useWeb3ModalAccount()
	const { disconnect } = useDisconnect()
	const { open } = useWeb3Modal()

	// 合约信息
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']

	const { onSendTransaction } = useSendTransaction()

	const {
		isCheck,
		totalCount,
		isExeAddress,
		treeDataTotal: treeDataTotalHooks,
		currentDayCount,
		aUsdtBalance,
	} = useChatHooks().data
	// const { mutate } = useChatHooks()

	const [userInfo, setUserInfo] = useState<IUserInfo>(UserInfoInit)
	const [loading, setLoading] = useState<boolean>(false)
	const [address, setAddress] = useState<string>('')
	// 是否搜索
	const [isSearch, setIsSearch] = useState<boolean>(false)

	// rebate
	const [rebateAddress, setRebateAddress] = useState<string>('')
	const [rebateGrade, setRebateGrade] = useState<number>(0)
	// loading
	const [rebateLoading, setRabateLoading] = useState<boolean>(false)

	const [withdrawAmount, seWithdrawAmount] = useState<string>('')
	const [withLoading, setWithLoading] = useState<boolean>(false)

	// 总用户列表
	const [treeDataTotal, setTreeDataTotal] = useState<DataNode[]>([])

	useEffect(() => {
		if (treeDataTotalHooks.length > 0) setTreeDataTotal(treeDataTotalHooks)
	}, [treeDataTotalHooks])
	// 分页
	const [currentPage, setCurrentPage] = useState<number>(1)
	const pageSize = 10

	const onLoadDataTotal = async ({ key, children }: any) =>
		new Promise<void>(async resolve => {
			if (children) {
				resolve()
				return
			}
			let newList = await serverQlUserInfo(key.substring(0, 42)).then(res => {
				return res.teamAddressList.map((item, keys) => {
					return {
						title: item,
						key: `${item}-${keys}-${nanoid(10)}`,
					}
				})
			})
			console.log('newList', newList)
			setTimeout(() => {
				setTreeDataTotal(origin => updateTreeData(origin, key, newList))
				resolve()
			}, 300)
		})

	const [treeData, setTreeData] = useState<DataNode[]>([])

	const onLoadData = async ({ key, children }: any) =>
		new Promise<void>(async resolve => {
			if (children) {
				resolve()
				return
			}
			let newList = await serverQlUserInfo(key.substring(0, 42)).then(res => {
				console.log('res', res)
				return res.teamAddressList.map((item, keys) => {
					return {
						title: item,
						key: `${item}-${keys}-${nanoid(10)}`,
					}
				})
			})
			setTimeout(() => {
				setTreeData(origin => updateTreeData(origin, key, newList))
				resolve()
			}, 300)
		})

	// 搜索用户信息
	const handleSearch = async (add: string) => {
		console.log('add', add)
		try {
			const promise = await Promise.all([serverQlUserInfo(add)]).then(async res => {
				const user: any = res[0]
				const [rebate, teamVolume] = await multicall(currentChainId, {
					contracts: [
						{
							address: VaultProxy_ADDRESS,
							abi: VAULTPROXY,
							functionName: 'users',
							args: [add],
						},
						{
							address: VaultProxy_ADDRESS,
							abi: VAULTPROXY,
							functionName: 'rebate',
							args: [add],
						},
					],
				}).then(res => {
					const users = res[0].result as number[],
						rebate = res[1].result as number
					const teamVolume = users[2]
					return [rebate, teamVolume]
				})
				return {
					user: {
						stakeAmount: formatEther(user.stakeAmount),
						withdrawnAmount: formatEther(user.withdrawnAmount),
						rewardPaidAmount: formatEther(user.rewardPaidAmount),
						teamAddressTotal: teamVolume,
						teamAddressList: user.teamAddressList,
						teamStakeAmount: formatEther(user.teamStakeAmount),
						teamWithdrawnAmount: formatEther(user.teamWithdrawnAmount),
						teamRewardPaidAmount: formatEther(user.teamRewardPaidAmount),
						referrer: user.referrer,
						stakeAmountTotal: formatEther(user.stakeAmountTotal),
						leavel: LeavelValueInit[rebate] ?? 0,
					},
					teamAddressList: user.teamAddressList.map((item: any, keys: number) => {
						return {
							key: `${item}-${keys}-${nanoid(10)}`,
							title: item,
						}
					}),
				}
			})

			setTreeData(promise.teamAddressList)
			console.log('promise', promise)
			setLoading(false)
			setUserInfo(promise.user as any)
		} catch (error) {
			console.log('err', error)
			setLoading(false)
		}
	}

	// 设置信息提交
	const handleSubmit = async () => {
		setRabateLoading(true)
		if (!isAddress(rebateAddress)) {
			toast.error('使用者地址錯誤', { id: 'CountError' })
			setRabateLoading(false)
			return
		}
		try {
			let abiData = [rebateAddress, rebateGrade]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'setRebate',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success('設定成功', { id: 'CountSetSuccess', duration: 2000 })
			setRabateLoading(false)
			setRebateAddress('')
			setRebateGrade(0)
		} catch (error: any) {
			toast.error(error?.message || error, { id: 'CountSetErr', duration: 2000 })
			setRabateLoading(false)
		}
	}

	// 值班地址-提取金额
	const handleSubmitWith = async () => {
		setWithLoading(true)
		if (withdrawAmount === '' || isNaN(Number(withdrawAmount))) {
			toast.error('請輸入正確的提取金額', { id: 'SubmitWithErr', duration: 2000 })
			setWithLoading(false)
			return
		}
		const max = new BigNumber(aUsdtBalance)
			.times(WITH_AMOUNT_NUM)
			.toString()
			.replace(/^(.*\..{4}).*$/, '$1')
		if (Number(max) < Number(withdrawAmount)) {
			toast.error(`最多可以提領的金額是：${max}`, { id: 'SubmitWithErr', duration: 2000 })
			setWithLoading(false)
			return
		}
		if (Number(aUsdtBalance) < Number(withdrawAmount)) {
			toast.error(`最多可以提領的金額是：${aUsdtBalance}`, { id: 'SubmitWithErr', duration: 2000 })
			setWithLoading(false)
			return
		}
		try {
			let abiData = [parseEther(withdrawAmount)]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'withdraw',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success('提取成功', { id: 'SubmitWithSuccess', duration: 2000 })
			setWithLoading(false)
			seWithdrawAmount('')
			// mutate()
		} catch (error: any) {
			toast.error(error?.message || error, { id: 'SubmitWithErr', duration: 2000 })
			setWithLoading(false)
		}
	}

	const InfoData = useCallback(({ title, value, isCol = false }: { title: string; value: string | number; isCol?: boolean }) => {
		return (
			<div
				className={`flex ${isCol ? 'flex-col items-start' : 'items-center'} gap-2 border-b px-4 pb-3 last:border-none last:pb-0`}
			>
				<SubTitle title={title} />
				<div className="break-all text-base">{numFormat(value)}</div>
			</div>
		)
	}, [])

	if (!isCheck && isConnected)
		return (
			<div className="flex h-screen w-full flex-col items-center justify-center px-10">
				<div className="mb-4 text-sm">帳號沒有查看權限</div>
				<Button
					onClick={() => {
						disconnect()
						window.sessionStorage.removeItem(address as string)
					}}
					className="tail-btn-no-chat"
				>
					Disconnect
				</Button>
			</div>
		)

	return (
		<>
			{!isConnected ? (
				<div className="flex h-screen w-full flex-col items-center justify-center px-10">
					<div className="mb-4 text-sm">請先連結錢包</div>
					<Button onClick={() => open()} className="tail-btn-active mb-5 w-full">
						<span>連線</span>
					</Button>
				</div>
			) : (
				<div className="flex w-full flex-col items-start gap-4 px-4 py-6">
					<div className="text-2xl font-bold">setRebate</div>
					<div className="my-3 flex w-full flex-col items-start">
						<div className="mb-1 text-sm">用戶地址：</div>
						<Input
							value={rebateAddress}
							onChange={({ target }) => setRebateAddress(target.value)}
							placeholder="请输入用户地址"
							classNames={{
								inputWrapper: 'tail-input-wrapper h-10',
								input: 'text-sm text-white',
							}}
						/>
						<div className="mb-1 mt-4 text-sm">用户等級：</div>
						<Select
							options={SelectOptions}
							defaultValue={rebateGrade}
							value={rebateGrade}
							disabled={rebateLoading}
							onChange={value => setRebateGrade(value)}
							className="w-1/2"
						/>
						<div className="mt-4 flex w-full items-center justify-center">
							<Button isLoading={rebateLoading} onClick={handleSubmit} className="tail-btn-active min-w-[7.8125rem]">
								<span className="text-white">提交</span>
							</Button>
						</div>
					</div>
					{/* <div className="text-2xl font-bold">今日值班經理</div>
					<div className="mb-1 text-sm">-</div>
					<div className="flex gap-2 text-sm">
						<span>當前餘額:</span>
						<span>{numFormat(aUsdtBalance)}</span>
					</div>
					<div className="flex gap-2 text-sm">
						<span>最多可提出:</span>
						<span>
							{new BigNumber(aUsdtBalance)
								.times(WITH_AMOUNT_NUM)
								.toString()
								.replace(/^(.*\..{4}).*$/, '$1')}
						</span>
						<span>(5%)</span>
					</div> */}

					{/* account?.toLowerCase() === dutyAddress?.toLowerCase() */}
					{/* {!isExeAddress && (
						<div className="flex w-full flex-col items-start">
							<div className="mb-2 text-sm">提現金額</div>
							<Input
								defaultValue={withdrawAmount}
								value={withdrawAmount}
								onValueChange={seWithdrawAmount}
								// onChange={({ target }) => seWithdrawAmount(target.value)}
								placeholder="請輸入提現金額"
								className="w-full"
							/>

							<div className="mt-4 flex w-full items-center justify-center">
								<Button
									isLoading={withLoading}
									onClick={handleSubmitWith}
									style={{
										background: '#1890ff',
									}}
									className="h-10 min-w-20 !border-[#1890ff] bg-[#1890ff] hover:bg-[#1890ff]"
								>
									<span className="text-white">提現</span>
								</Button>
							</div>
						</div>
					)} */}
					{/* <div className="text-2xl font-bold">VaultProxy 總數據統計</div>
					<div className="flex w-full flex-col gap-3 rounded-xl border py-4 shadow">
						<InfoData title="質押總額" value={totalCount.stakeAmountTotal} />
						<InfoData title="質押餘額" value={totalCount.stakeAmount} />
						<InfoData title="實質餘額" value={totalCount.actualBalance} />
						<InfoData title="提出金額" value={totalCount.managerWithdrawnAmount} />
						<InfoData title="利潤總額" value={totalCount.profitTotalAmount} />
						<InfoData title="贖回金額" value={totalCount.withdrawnAmount} />
						<InfoData title="總質押地址數" value={totalCount.addressTotal} />
						<InfoData title="有效地址數" value={totalCount.efficientAddressTotal} />
						<InfoData title="當日質押" value={currentDayCount.stakeAmount} />
						<InfoData title="當日提出" value={currentDayCount.managerWithdrawnAmount} />
						<InfoData title="當日利潤" value={currentDayCount.profitTotalAmount} />
						<InfoData title="當日贖回" value={currentDayCount.withdrawnAmount} />
					</div>
					<div className="text-2xl font-bold">VaultProxy 總用戶列表</div>
					<Tree
						loadData={onLoadDataTotal}
						treeData={treeDataTotal
							.filter((item, key) => key >= pageSize * (currentPage - 1))
							.filter((item, key) => key < pageSize)}
						onSelect={async (selectedKeys, keys) => {
							let strs = selectedKeys[0].toString().substring(0, 42)
							if (isAddress(strs)) {
								await setAddress(strs)
								setTreeData([])
								setLoading(true)
								setIsSearch(true)
								handleSearch(strs)
							}
						}}
					/>
					{Math.ceil(new BigNumber(treeDataTotal.length).div(pageSize).toNumber()) > 0 && (
						<Pagination
							size="sm"
							page={currentPage}
							initialPage={1}
							total={Math.ceil(new BigNumber(treeDataTotal.length).div(pageSize).toNumber())}
							onChange={page => {
								console.log(page)
								setCurrentPage(page)
							}}
						/>
					)} */}

					{/* <div className="mt-4 text-2xl font-bold">使用者資料統計</div> */}
					{/* <div className="flex w-full flex-col gap-4">
						<InputAntd.Search
							defaultValue={address}
							value={address}
							placeholder="請輸入使用者地址"
							onChange={({ target }) => {
								setAddress(target.value)
								if (!isAddress(target.value)) {
									setIsSearch(false)
									setUserInfo(UserInfoInit)
								}
							}}
							loading={loading}
							onSearch={value => {
								if (!isAddress(value)) {
									toast.error('用戶地址不正確', { id: 'countSearchErr' })
									setAddress('')
									return
								}
								setTreeData([])
								setLoading(true)
								setIsSearch(true)
								handleSearch(value)
							}}
						/>
						{isSearch && (
							<div className="flex w-auto flex-col gap-3 rounded-xl border py-4 shadow">
								{loading ? (
									<div className="flex w-full flex-col items-center justify-center py-8">
										<Spinner color="white" />
										<span className="pt-4 text-sm text-white">載入中...</span>
									</div>
								) : (
									<>
										<div className="mb-2 text-center text-sm text-rose-600">目前用戶個人資訊</div>
										<InfoData title="質押總額" value={userInfo.stakeAmountTotal} />
										<InfoData title="質押餘額" value={userInfo.stakeAmount} />
										<InfoData title="贖回金額" value={userInfo.withdrawnAmount} />
										<InfoData title="已發金額" value={userInfo.rewardPaidAmount} />
										<InfoData isCol title="邀請人位址" value={userInfo.referrer} />
										<div className="mb-2 text-center text-sm text-rose-600">目前使用者團隊資訊</div>
										<InfoData title="團隊等級" value={userInfo.leavel} />
										<InfoData title="團隊質訂金金額" value={userInfo.teamStakeAmount} />
										<InfoData title="團隊贖回金額" value={userInfo.teamWithdrawnAmount} />
										<InfoData title="團隊已發金額" value={userInfo.teamRewardPaidAmount} />
										<InfoData title="團隊數量" value={userInfo.teamAddressTotal} />
										<div className="flex h-auto flex-col items-start gap-2 px-4">
											<SubTitle title="團隊成員" />
											<Tree
												loadData={onLoadData}
												treeData={treeData}
												onSelect={async (selectedKeys, keys) => {
													let strs = selectedKeys[0].toString().substring(0, 42)
													if (isAddress(strs)) {
														await setAddress(strs)
														setTreeData([])
														setLoading(true)
														setIsSearch(true)
														handleSearch(strs)
													}
												}}
											/>
											{userInfo.teamAddressList.length === 0 && <div>{'-'}</div>}
										</div>
									</>
								)}
							</div>
						)}
					</div> */}
				</div>
			)}
		</>
	)
}

export default CountPage
