import menu from './ru/menu'
import home from './ru/home'
import chat from './ru/chat'
import other from './ru/other'
import about from './ru/about'
import swap from './ru/swap'
const lang = {
	'app.switch.language.tips': 'Переключение на {{msg}} успешно!',
	'app.language': 'Язык',
	'app.loading': 'Загрузка...',
	'connect.sign': 'Подпись...',
	'connect.btn': 'Подключить',
	'connect.logout': 'Выйти',
	'connect.sign.error': 'Ошибка подписи',

	'layout.menu.stake': 'Стейкинг',
	'layout.menu.withdraw': 'Вывод',
	'layout.menu.team': 'Сеть',
	'layout.menu.share': 'Поделиться',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
