const swap = {
	'swap.tips.no.wallet': 'Сначала подключите свой кошелек',
	'swap.tips.no.number': 'Пожалуйста, введите количество',
	'swap.tips.no.address': 'Введите свой адрес получения',
	'swap.tips.address.error': 'Ошибка адреса',
	'swap.tips.error': 'Обмен не удался',
	'swap.tips.max': 'Максимальная сумма: {{msg}}',
	'swap.tips.min': 'Минимальная сумма: {{msg}}',

	'swap.card.send': 'Отправить',
	'swap.card.receive': 'Получить',
	'swap.card.tips': 'Введите валюту или билеты',
	'swap.card.min': 'мин:',
	'swap.card.max': 'макс:',

	'swap.btn.title': 'Пункт назначения',
	'swap.input.tips': 'Адрес платежа',
	'swap.title.order': 'Тип заказа',

	'swap.tab.1': 'Фиксированный обменный курс (1,0%)',
	'swap.tab.2': 'Плавающий обменный курс (0,5%)',

	'swap.btn.tipss': 'В чем разница? ',
	'swap.btn.swap': 'Начать обмен',

	'swap.tips.1': `Используя этот сайт и совершая обмен, вы соглашаетесь с Fast Coin Mixer's`,
	'swap.tips.2': 'Условия обслуживания',
	'swap.tips.3': 'и',
	'swap.tips.4': 'Политика конфиденциальности',
	'swap.tips.5': '. ',

	'swap.diff.title': 'В чем разница между фиксированным и плавающим обменным курсом? ',

	'swap.paste.title': 'Вставить',
	'swap.help.title': 'FAQ',
	'swap.help.1':
		'Тремя способами: по электронной почте (если вы оставите свой адрес электронной почты), на нашем сайте (если ваш браузер поддерживает кэширование) или путем просмотра блокчейна через обмен ссылками на ваш заказ. ',
	'swap.help.2': `Регистрация не требуется, нет необходимости предоставлять личную информацию. Мы не удерживаем ваши средства, все обмены производятся мгновенно в полностью автоматическом режиме. `,
	'swap.help.3':
		'Честность — наш главный приоритет, поэтому мы обещаем быть полностью прозрачными и четко указывать все комиссии:',
	'swap.help.4': '• Если вы выбираете фиксированный обменный курс, он составляет 1%',
	'swap.help.5': '• Если вы выберете плавающую ставку, она составит 0,5%',
	'swap.help.6': 'Перейти на страницу часто задаваемых вопросов',
	'swap.help.title1': 'Как мне отследить свой заказ? ',
	'swap.help.title2': 'Почему я могу вам доверять? ',
	'swap.help.title3': 'Есть ли у вас какие-либо скрытые комиссии? ',

	'swap.tips.title': 'Доверие с 2018 года',
	'swap.tips.card.title1': 'Экономьте время',
	'swap.tips.card.title2': 'Сделать обмен',
	'swap.tips.card.title3': 'Экономьте деньги',
	'swap.tips.card.content1': 'Поскольку весь процесс автоматизирован, скорость обмена самая высокая',
	'swap.tips.card.content2': 'Выберите правильную стратегию и совершите выгодный обмен',
	'swap.tips.card.content3': 'Лучший курс обмена, самая низкая комиссия',

	'swap.trade.title': 'Недавние транзакции',
}

export default swap
